import React, { useState } from 'react';
import './instruction.scss';
import { useAppData } from '../../AppContext';
import { completeInstruction } from '../../http';

import present from "./Images/present.png";

function Instruction() {

    const { setFirstTime, handleEditCoins } = useAppData();

    const info = [
        {
            id: 1,
            image: "https://bearlock.xyz/media/instruction/1.png",
            title: "Earn coins with every tap!",
            icon: "https://bearlock.xyz/media/instruction/c1.png",
            subtext: "Use boosters to multiply your coins!"
        },
        {
            id: 2,
            image: "https://bearlock.xyz/media/instruction/2.png",
            title: "Boost your Duck! ",
            icon: "https://bearlock.xyz/media/instruction/c2.png",
            subtext: "Work your way up from a Recruit to a Hero Duck"
        },
        {
            id: 3,
            image: "https://bearlock.xyz/media/instruction/3.png",
            title: "Perfect your skills.",
            icon: "https://bearlock.xyz/media/instruction/c3.png",
            subtext: "Maximize the level of your cards for more income."
        },
        {
            id: 4,
            image: "https://bearlock.xyz/media/instruction/4.png",
            title: "Invite friends and receive exclusive bonuses!",
            icon: "https://bearlock.xyz/media/instruction/c4.png",
            subtext: "Secure valuable coins for you and your friend!"
        },
        {
            id: 5,
            image: "https://bearlock.xyz/media/instruction/5.png",
            title: "Become part of our communities and meet other players!",
            icon: "https://bearlock.xyz/media/instruction/c5.png",
            subtext: ""
        },
        {
            id: 6,
            image: "https://bearlock.xyz/media/instruction/6.png",
            title: "Use coins and secure an airdrop at the token launch!",
            icon: "https://bearlock.xyz/media/instruction/c6.png",
            subtext: "Don't forget to invite your friends! Good luck!"
        },
        {
            id: 7,
            image: "",
            title: "",
            icon: "",
            subtext: ""
        }
    ];

    const [currentPage, setCurrentPage] = useState(0);

    const handleNext = () => {
        if (currentPage === info.length - 1) {
            setFirstTime(false);
        } else {
            setCurrentPage(currentPage + 1);
        }
    };

    const lastCLick = () => {
        completeInstruction();
        handleEditCoins(5000);
        setFirstTime(false);
    };

    return (
        <div className='instruction_container'>
            {currentPage === 6 ? (
                <>
                    <img className='start' src={present} onClick={() => lastCLick()} />
                </>
            ) : (
                <>
                    <img className='image_main' src={info[currentPage].image || 'path/to/placeholder.png'} alt={info[currentPage].title} />
                    
                    <div className='bottom_instruction'>
                        <div className='bottom_section'>
                            <div className='left_box'>
                                <img src={info[currentPage].icon} alt='Icon' />
                            </div>
                            <div className='right_box'>
                                <h2>{info[currentPage].title}</h2>
                                <p>{info[currentPage].subtext}</p>
                            </div>
                        </div>
                        <div className='next_button'>
                            <button onClick={handleNext}>Next</button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default Instruction;
