import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import PlayOnPhone from './Components/PlayOnPhone/PlayOnPhone';
import './i18n';
import SiteDisabled from './Components/SiteDisabled/SiteDisabled';
import Loading from './Components/Loading/Loading';
import { TonConnectUIProvider } from '@tonconnect/ui-react';

const root = ReactDOM.createRoot(document.getElementById('root'));

window.Telegram.WebApp.expand();

root.render(<Loading />);

setTimeout(() => {
    root.render( 
    <TonConnectUIProvider manifestUrl="https://bearlocknftproject.pages.dev/manifest.json">
        <App />
    </TonConnectUIProvider>
   );
}, 2000); 


