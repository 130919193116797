import React, { useState } from 'react';
import { useAppData } from '../../AppContext';
import { useNavigate } from 'react-router-dom';
import Back from '../Back';
import './boost.scss';
import { useTranslation } from 'react-i18next';
import energylimit from "./energylimit.png"
import multitap from "./multitap.png"
import recharge from "./recharge.png"
import Footer from '../Footer/Footer';
import dollar_coin from "../../coin.png"

const UPGRADE_PRICES = {
    1: 10000,
    2: 20000,
    3: 80000,
    4: 160000,
    5: 320000,
    6: 640000,
    7: 1280000,
    8: 2560000,
    9: 5120000,
    10: 10240000,
    11: 20480000,
    12: 40960000,
    13: 81920000,
    14: 163840000,
    15: 327680000,
    16: 655360000,
};

export const Boost = () => {
    const { t } = useTranslation();
    const { coins, upgrades, handleEditCoins, purchaiseUpgrade, activateSpecial, specials } = useAppData();
    const [selectedUpgrade, setSelectedUpgrade] = useState(null);
    const [selectedSpecial, setSelectedSpecial] = useState(null);
    const navigate = useNavigate();

    const handleUpgradeClick = (upgrade) => {
        setSelectedUpgrade(upgrade);
    };

    const handleCloseModal = () => {
        setSelectedUpgrade(null);
        setSelectedSpecial(null);
    };

    const handleActivateSpecial = (specialId) => {
        activateSpecial(specialId);
        navigate('/');
    };

    const handleSpecialClick = (special) => {
        setSelectedSpecial(special);
    };

    const handleUpgrade = () => {
        if (selectedUpgrade) {
            const upgradeCost = getUpgradeCost(selectedUpgrade.level);
            if (coins >= upgradeCost) {
                purchaiseUpgrade(selectedUpgrade.id);
                handleEditCoins(-upgradeCost);
            }
        }
        setSelectedUpgrade(null);
    };

    const formatNumber = (num) => {
        if (num >= 1000000) {
            return (num / 1000000).toFixed(0) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(0) + 'K';
        }
        return num;
    };

    const getUpgradeCost = (upgradeLevel) => {
        return UPGRADE_PRICES[upgradeLevel] || null;
    };

    const isAffordable = (upgradeLevel) => {
        const upgradeCost = getUpgradeCost(upgradeLevel);
        return coins >= upgradeCost;
    };
    
    return (
        <div className='outer-container_boost'>
            <div className='container_glow'>
                <Footer />
                <div className='balance_container'>
                    <h2>Your balance</h2>
                    <div className='row_home'>
                        <div className="stats">
                            <img src={dollar_coin} className="stats-coin" alt="Coin icon" />
                            <div id="balance" className="coin-count">{coins.toLocaleString('en-US')}</div>
                        </div>
                    </div>
                </div>
                <div className='scrollable'>
                    <div className="free_boosts_title">Free Daily Boosters</div>
                    <div className='daily_boosts_container'>
                        {specials.map((special) => (
                            <div className='daily_boost' onClick={() => handleSpecialClick(special)}>    
                                <div className='img_boost_container'>
                                    <img src={getSpecialInfo(special.id).src} className='boost_img' alt={special.id} />
                                </div>
                                <div className='boost_container'>
                                    <div className="boost_name">{getSpecialInfo(special.id).displayName}</div>
                                    <div className="count_container">
                                        <span>{special.count}</span>/6 available
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
    
                    <div className='additional_boosts'>Boosters</div>
                    <div className="tab-block">
                        {upgrades.map((upgrade) => (
                            <div key={upgrade.id} className="tab-subblock" onClick={() => handleUpgradeClick(upgrade)}>
                                <div className='img_boost_container'>
                                    <img src={getUpgradeInfo(upgrade.id, t).icon} className="img_boost" />
                                </div>
                                <div>
                                    <div className="shop-upgrade-name">{getUpgradeInfo(upgrade.id, t).title}</div>
                                    <div className="shop-upgrade-description">
                                        {upgrade.level !== 16 ? (
                                            <div>
                                                <span className='row_boosts'>
                                                    <img style={{ width: "20px" }} src={dollar_coin} alt="coin" />
                                                    <span>{formatNumber(getUpgradeCost(upgrade.level))}</span>
                                                    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="4" cy="4" r="4" fill="#AFAFAF"/>
                                                    </svg>
                                                    {`${upgrade.level} lvl`}
                                                </span>
                                            </div>
                                        ) : (
                                            <div>{t('boost.maxLevelReached')}</div>
                                        )}
                                    </div>
                                </div>
                                {getUpgradeCost(upgrade.level) !== null && (
                                    <button className={`tab-button-arrow ${isAffordable(upgrade.level) ? '' : ''}`}>
                                        ›
                                    </button>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Back />
            {selectedUpgrade && (
                <div className="modal">
                    <div className='container_glow_modal'>
                        <div className="modal-container">
                            <span className="close" onClick={handleCloseModal}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="12" r="10" fill="#CACACA" fill-opacity="0.46"/>
                                    <path d="M16 8L8 16" stroke="#131313" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M8 8L16 16" stroke="#131313" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                            <div className="modal-content">
                                <div className="modal-info">
                                    <img src={getUpgradeInfo(selectedUpgrade.id, t).icon} className="modal-image" />
                                    <div className="popup_text">
                                        <h2 className='modal-title'>{getUpgradeInfo(selectedUpgrade.id, t).title}</h2>
                                        <p>{getUpgradeInfo(selectedUpgrade.id, t).description}</p>
                                        <p>{getUpgradeInfo(selectedUpgrade.id, t).effect}</p>
                                    </div>
                                    <div className="shop-upgrade-description">
                                        {selectedUpgrade.level !== 16 ? (
                                            <div className='popup_upgrade_info'>
                                                <span className='row_boosts'>
                                                    <img src={dollar_coin} alt="coin" />
                                                    <span>{(getUpgradeCost(selectedUpgrade.level)).toLocaleString('en-US')}</span>
                                                    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="4" cy="4" r="4" fill="#AFAFAF"/>
                                                    </svg>
                                                    {`${selectedUpgrade.level} lvl`}
                                                </span>
                                            </div>
                                        ) : (
                                            <div>{t('boost.maxLevelReached')}</div>
                                        )}
                                    </div>
                                    {selectedUpgrade.level !== 16 && (
                                        <button className={`button-long ${isAffordable(selectedUpgrade.level) ? '' : 'disabled'}`} onClick={handleUpgrade}>
                                            Go ahead
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {selectedSpecial && (
                <div className="modal">
                    <div className='container_glow_modal'>
                        <div className="modal-container">
                            <span className="close" onClick={handleCloseModal}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="12" r="10" fill="#CACACA" fill-opacity="0.46"/>
                                    <path d="M16 8L8 16" stroke="#131313" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M8 8L16 16" stroke="#131313" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                            <div className="modal-content">
                                <div className="modal-info">
                                    <img src={getSpecialInfo(selectedSpecial.id).src} className="modal-image" />
                                    <div className="popup_text">
                                        <h2 className='modal-title'>{getSpecialInfo(selectedSpecial.id).displayName}</h2>
                                        <p>{getSpecialInfo(selectedSpecial.id).description}</p>
                                    </div>
                                    <div className="shop-upgrade-description">
                                        <div className='popup_upgrade_info'>
                                            <span className='row_boosts'>
                                                <img src={dollar_coin} alt="coin" />
                                                <span>Free</span>
                                            </span>
                                        </div>
                                    </div>
                                    <button className='button-long' onClick={() => handleActivateSpecial(selectedSpecial.id)}>Go ahead</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div >
    );
};

const getSpecialInfo = (name) => {
    switch (name) {
        case "full_energy":
            return {
                src: recharge,
                displayName: "Full energy",
                description: "Recharge your energy to the maximum and do another round of mining",
                effect: "Recharge your energy"
            };
        default:
            return { src: null, displayName: "Unknown" };
    }
};

const getUpgradeInfo = (name, t) => {
    switch (name) {
        case "multitap":
            return {
                name: "",
                icon: multitap,
                title: "Multitap",
                description: "Increase the amount of coins you can earn per tap",
                effect: "+1 Coin per tap for each level."
            };
        case "energyLimit":
            return {
                name: "",
                icon: energylimit,
                title: "Energy Limit",
                description: "Increase the amount of energy",
                effect: "+500 Energy for each level."
            };
        default:
            return null;
    }
};
