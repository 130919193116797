import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import { getUserDaylys, getUserMemes, getUserInfo, getUserUpgrades, sendCoinsAndEnergy,getUserSpecials, getUserRefferedCount, sendSpecial, sendUpgrade } from './http';
import { getAllPets, getUserTasks, getAllLeagues } from './http';
import Loading from './Components/Loading/Loading';
import dollar_coin from './coin.png'
export const AppContext = createContext({
    USER_ID: null,
    user: null,
    coins: 10,
    energy: 0,
    clickCost: 1,
    energyLimit: 0,
    energyRechargeDelay: 1,
    refferedCount: 0,
    upgrades: null,
    specials: null,
    specialMode: null,
    allPets: [],
    pet: [],
    food: [],
    memes: [],
    passiveIncome: 0,
    passiveIncomePerHour: 0,
    total_coins_earned: 0,
    tasks: [],
    leagues: [],
    dailys: [],
});

export const useAppData = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [unit, setUnit] = useState(null);
    const [coins, setCoins] = useState(0);
    const [skin, setSkin] = useState("");
    const [playerLeague, setPlayerLeague] = useState([]);

    const [total_coins_earned, setTotal_coins_earned] = useState(0);
    const [passiveIncome, setpassiveIncome] = useState(0);
    const [passiveIncomePerHour, setpassiveIncomePerHour] = useState(0);
    const [refferedCount, setRefferedCount] = useState(0);
    const [refferedCoinsCount, setRefferedCoinsCount] = useState(0);
    const [energy, setEnergy] = useState(0);
    const [clickCost, setClickCost] = useState(1);
    const [energyLimit, setEnergyLimit] = useState(0);
    const [userPhoto, setUserPhoto] = useState("");
    const [unitName, setUnitName] = useState("");
    const [fisrtTime, setFirstTime] = useState(false);
    const [energyRechargeDelay, setEnergyRechargeDelay] = useState(1);
    const [allPets, setAllPets] = useState([]);
    const [notification, setNotification] = useState([]);
    const [Pet, setPet] = useState([]);
    const [food, setFood] = useState([]);
    const [memes, setMemes] = useState([]);
    const [dailys, setDailys] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [leagues, setLeagues] = useState([]);
    const [triggerRender, setTriggerRender] = useState(false);
    const [username, setUsername] = useState("");
    const [clickCount, setClickCount] = useState(0);

    const [upgrades, setUpgrades] = useState([
        { id: "multitap", level: 1 },
        { id: "energyLimit", level: 1 }
    ]);

    const [specials, setSpecials] = useState([
        { id: "full_energy", count: 1 },
    ]);

    const [specialMode, setSpecialMode] = useState(null);
    const [specialModeTimer, setSpecialModeTimer] = useState(null);
    const handleEditCoinsClick = (value) => {
        if (value > 0) {
            setTotal_coins_earned(total_coins_earned + value)
        }
        setCoins(coins + value)
    };

    useEffect(() => {
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.ready();
            window.Telegram.WebApp.expand();
            window.Telegram.WebApp.setHeaderColor('#000000');
        }
    }, []);

    const activateSpecial = async (specialId) => {
        const specialIndex = specials.findIndex(special => special.id === specialId);

        if (specials[specialIndex].count > 0) {
            const updatedSpecials = [...specials];
            updatedSpecials[specialIndex].count -= 1;
            setSpecials(updatedSpecials);

            await sendSpecial(specialId);

            if (specialId === 'full_energy') {
                setEnergy(energyLimit)
            }
        } else {
            console.log(`Special item ${specialId} is not available or out of stock.`);
        }
    }

    const enableSpecialMode = (mode, bonus, duration) => {
        disableSpecialMode();
        setSpecialMode({ mode, bonus });

        const timer = setTimeout(() => {
            disableSpecialMode();
        }, duration * 1000);
        setSpecialModeTimer(timer);
    };

    const disableSpecialMode = () => {
        setSpecialMode(null);
        clearTimeout(specialModeTimer);
    };

    const handleEditCoins = (value) => {
        const totalDuration = 1000;
        const targetCoins = coins + value;
        let magnitude = Math.floor(Math.log10(Math.abs(value))) - 2;
        let step = magnitude > 0 ? Math.pow(10, magnitude) : 1;
        step = value > 0 ? step : -step;

        const stepsCount = Math.floor(Math.abs(value) / Math.abs(step));
        const lastStep = value % step;

        const interval = totalDuration / (stepsCount + (lastStep !== 0 ? 1 : 0));

        const handleStep = () => {
            setCoins(prev => {
                const nextValue = prev + step;
                if ((step > 0 && nextValue >= targetCoins) || (step < 0 && nextValue <= targetCoins)) {
                    return targetCoins;
                }
                setTimeout(handleStep, interval);
                return nextValue;
            });
        };

        handleStep();
    };

    const handleEditEnergy = (value) => {
        if (energy <= energyLimit && energy > 0) {
            setEnergy(energy + value);
        }
    }

    const updateUpgradesValue = () => {
        if (upgrades && upgrades.length > 0) {
            upgrades.forEach(upgrade => {
                if (upgrade.id === 'energyLimit') {
                    setEnergyLimit(upgrade.level * 1000);
                } else if (upgrade.id === 'multitap') {
                    setClickCost(upgrade.level);
                }
            });
        }
    }

    const getUserInfoData = async () => {
        try {
            const data = await getUserInfo();
            setNotification(data.notifications)
            setSkin(data.skin);
            setUnit(data.unit)
            setPlayerLeague(data.league);
            setCoins(data.coins_count);
            setEnergy(data.energy_count);
            setTotal_coins_earned(data.total_coins_earned);
            setpassiveIncome(data.passive_income);
            setpassiveIncomePerHour(data.coins_per_hour);
            setUserPhoto(data.profile_photo);
            setUnitName(data.unit_name);
            setFirstTime(data.is_first_time_seen)
            setUsername(data.name)

            setLoading(false); 
        } catch (error) {
            console.error("Error fetching user info", error);
            setLoading(false); 
        }
    };

    const getUserMemesData = async () => {
        const data = await getUserMemes();

        setMemes(data.memes);
    }

    const getUserDaylysData = async () => {
        const data = await getUserDaylys();

        setDailys(data.DailyTasks);
    }

    const getUserUpgradesData = async () => {
        const data = await getUserUpgrades();

        if (data) {
            const formattedUpgrades = [
                { id: "multitap", level: data.multitap_level || 0 },
                { id: "energyLimit", level: data.energy_limit_level || 0 }
            ];
            setEnergyRechargeDelay(data.multitap_level)
            setUpgrades(formattedUpgrades);
        } else {
            setUpgrades([]);
        }
    }

    const purchaiseUpgrade = (upgradeId) => {
        const upgradeIndex = upgrades.findIndex(upgrade => upgrade.id === upgradeId);

        const newUpgrades = [...upgrades];
        newUpgrades[upgradeIndex].level += 1;
        setUpgrades(newUpgrades);

        sendUpgrade(upgradeId);
    }

    const getUserRefferedCountData = async () => {
        const data = await getUserRefferedCount();

        if (data) {
            setRefferedCount(data.friends_reffered_count);
            setRefferedCoinsCount(data.total_earned_by_friends);
        }
    }

    const getUserSpecialsData = async () => {
        const data = await getUserSpecials();

        if (data) {
            const formattedSpecials = Object.entries(data).map(([key, value]) => ({
                id: key,
                count: value
            }));

            setSpecials(formattedSpecials);
        }
    }

    const getAllPetstData = async () => {
        const data = await getAllPets();
        setAllPets(data.pets);
    }

    const getUserTasksData = async () => {
        const data = await getUserTasks();

        setTasks(data.tasks);
    };

    const getAllLeaguesData = async () => {
        const data = await getAllLeagues();
        setLeagues(data.leagues);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const results = await Promise.all([
                    getUserInfoData(),
                    getUserUpgradesData(),
                    getUserSpecialsData(),
                    getUserRefferedCountData(),
                    getAllPetstData(),
                    getUserTasksData(),
                    getAllLeaguesData(),
                    getUserMemesData(),
                    getUserDaylysData()
                ]);

                setRefferedCount(results[3]);
                setPet(results[5]);
                setFood(results[6]);
                setMemes(results[7]);
                setLeagues(results[8]);
                setDailys(results[8]);

            } catch (error) {
                console.error("Error fetching data", error);
            }
        };

        fetchData();
    }, [triggerRender]);

    useEffect(() => {
        if (upgrades && upgrades.length > 0) {
            updateUpgradesValue();
        }
    }, [upgrades]);

    useEffect(() => {
        const incomePerSecond = passiveIncomePerHour / 3600; 
        const interval = setInterval(() => {
            setCoins((prevCoins) => prevCoins + incomePerSecond);
        }, 1000);

        return () => clearInterval(interval); 
    }, [passiveIncomePerHour]); 

    
    const clickCountRef = useRef(0);

    useEffect(() => {
        const clickSyncInterval = setInterval(() => {
            sendCoinsAndEnergy(clickCountRef.current);
            clickCountRef.current = 0; 
        }, 5000);

        return () => clearInterval(clickSyncInterval);
    }, [clickCost]);

    const incrementClickCount = () => {
        clickCountRef.current += 1;
    };

    return (
        <AppContext.Provider value={{
            incrementClickCount,
            setClickCount,
            setFirstTime,
            fisrtTime,
            unitName,
            setNotification,
            notification,
            setUnit,
            unit,
            skin,
            playerLeague,
            coins,
            handleEditCoins,
            energy,
            handleEditEnergy,
            energyLimit,
            energyRechargeDelay,
            clickCost,
            upgrades,
            specials,
            purchaiseUpgrade,
            getUserInfoData,
            getUserMemesData,
            getUserDaylysData,
            specialMode,
            activateSpecial,
            allPets,
            Pet,
            food,
            memes,
            dailys,
            passiveIncome,
            passiveIncomePerHour,
            total_coins_earned,
            tasks,
            leagues,
            refferedCount,
            dollar_coin,
            setpassiveIncomePerHour,
            setpassiveIncome,
            setEnergy,
            handleEditCoinsClick,
            userPhoto,
            setTriggerRender,
            setPet,
            setTotal_coins_earned,
            refferedCoinsCount,
            energyRechargeDelay,
            username
        }}>
            {loading ? <Loading /> : children}
        </AppContext.Provider>
    );
};
